import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
//import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
//import GetStarted from "../components/sections/getstarted"

const ProductPage = () => (
  <Layout>
    <SEO title="Vlutor" description = "Vlutor combines Augmented Reality with Muna, the AI Virtual Tutor for Audiovisual Learners, to create a fun learning experience." lang="en" 
    meta = {[{name: "ok",content: "here"}]} />
    <Navigation />
    <Header />
    <Footer />
  </Layout>
)

export default ProductPage
